export const userServiceUrl = 'https://api.wks.iqest.info/wks-user-service';
export const trainingServiceUrl = 'https://api.wks.iqest.info/wks-training-service';
export const logServiceUrl = 'https://api.wks.iqest.info/wks-log-service';
export const messageServiceUrl = 'https://api.wks.iqest.info/wks-message-service';
export const dmsUrl = 'https://api.wks.iqest.info/wks-dms-service';
export const fileProcessingServiceUrl = 'wss://api.wks.iqest.info/wks-file-processing-service/ws';
export const productRegistrationServiceUrl = 'https://api.wks.iqest.info/wks-product-registration-service';
export const keycloakUrl = 'https://keycloak.wks.iqest.info';
export const realm = 'wikisanitas-dev';
export const clientId = 'wikisanitas-dev-client';
export const bucket = 'traning-service-dev';
export const version = '1.0';
export const dmsApiVersion = 'v1.0';
export const userApiVersion = 'v1.0';
export const trainingApiVersion = 'v1.0';
export const logApiVersion = 'v1.0';
export const messageApiVersion = 'v1.0';
export const forumServiceShopLink = 'https://service.forumviasanitas.org/__testshop';
